<template>
  <section class="container mx-auto mt-6">
    <div class=" manage">
      <div class="col-span-1">
        <app-upload />
      </div>
      <div class="col-span-2">
        <div class="bg-white rounded border border-gray-200 relative flex flex-col">
          <div class="px-6 pt-6 pb-5 font-bold border-b border-gray-200">
            <span class="card-title">
              {{ $t('manage.my_songs') }}
            </span>
            <i class="fa fa-compact-disc float-right text-gray-400 text-2xl"></i>
          </div>
          <div class="p-6">
            <Loader v-if="loading"/>
            <div v-else-if="!songs.length">
              {{ $t('manage.placeholder') }}
            </div>
            <transition-group name="list">
              <composition-item
                v-for="song in sortedSongs"
                :key="song.url"
                :song="song"/>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import AppUpload from '@/components/Upload.vue';
import CompositionItem from '@/components/CompositionItem.vue';
import { ref, onBeforeMount, computed } from 'vue';
import { useStore } from 'vuex';
import Loader from '../components/Loader.vue';

const loading = ref(false);

const store = useStore();

const songs = computed(() => store.getters.manageSongs);

const sortedSongs = computed(() => [...songs.value]
  .sort((a, b) => (a.modified_name > b.modified_name ? 1 : -1)));

async function updateSongsList() {
  loading.value = true;
  await store.dispatch('songsCollectionFromFirebase');
  loading.value = false;
}

onBeforeMount(async () => {
  await updateSongsList();
});
</script>


<style lang="scss" scoped>
.manage {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 0;
  }
}
</style>
