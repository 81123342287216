<template>
  <vee-form
    :validation-schema="schema"
    :initial-values="song"
    @submit="submitForm">

    <div class="mb-3">
      <label class="inline-block mb-2">
        {{ $t('song.title') }}
      </label>

      <vee-field
        type="text"
        name="modified_name"
        class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300
            transition duration-500 focus:outline-none focus:border-black rounded"
        placeholder="Enter Song Title"/>

      <ErrorMessage class="text-red-600" name="modified_name"/>
    </div>

    <div class="mb-3">
      <label class="inline-block mb-2">
        {{ $t('song.genre') }}
      </label>

      <vee-field
        class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300
            transition duration-500 focus:outline-none focus:border-black rounded"
        placeholder="Enter Genre"
        name="genre"
        type="text"/>

      <ErrorMessage class="text-red-600" name="genre"/>
    </div>

    <button
      class="py-1.5 px-3 rounded text-white bg-purple-500 mr-3"
      type="submit"
      :disabled="in_submission"
    >
      {{ $t('global.submit') }}
    </button>

    <button
      class="py-1.5 px-3 rounded text-white bg-gray-600"
      type="submit"
      @click.prevent="$emit('closeForm')"
      :disabled="in_submission"
    >
      {{ $t('global.go_back') }}
    </button>
  </vee-form>
</template>

<script setup>
import { reactive } from "vue";

const props = defineProps({
  song: {
    required: true
  },
  in_submission: {
    type: Boolean
  }
})

const emits = defineEmits(['edit', 'closeForm'])

const schema = reactive({
  modified_name: 'required',
  genre: 'alpha_spaces',
})

const submitForm = (values) => {
  emits('edit', values)
}
</script>
