<template>
  <main>
    <section class="w-full mb-8 py-14 text-center text-white relative introduction">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain introduction__bg"
        style="background-image: url(/assets/img/song-header.png)">
      </div>

      <div class="container mx-auto flex items-center p-4">
        <button
          type="button"
          class="z-50 h-24 w-24 text-3xl bg-white text-black rounded-full focus:outline-none play-button"
          @click.prevent="playSong(song)"
          id="play-button"
        >
          <i class="fas"
             :class="{
            'fa-play': !isPlaying || currentSong.modified_name !== song.modified_name,
            'fa-pause': isPlaying && currentSong.modified_name === song.modified_name
          }"
          ></i>
        </button>
        <div class="z-50 text-left ml-8">
          <div class="text-3xl font-bold introduction__header" id="song-name">
            {{ song.modified_name }}
          </div>
          <div>
            {{ song.genre }}
          </div>
        </div>
      </div>
    </section>

    <section class="container mx-auto mt-6 comments-list" id="comments">
      <div class="bg-white rounded border border-gray-200 relative flex flex-col">
        <div class="px-6 pt-6 pb-5 font-bold border-b border-gray-200">
        <span class="card-title">
          {{ $t('song.comment_count', comments.length, {count: comments.length}) }}
        </span>
          <i class="fa fa-comments float-right text-gray-400 text-2xl"></i>
        </div>
        <div class="p-6">
          <div
            v-if="commentShowAlert"
            class="text-white text-center font-bold p-4 mb-4"
            :class="commentAlertVariant">
            {{ commentAlertMessage }}
          </div>

          <vee-form
            v-if="userLoggedIn"
            :validation-schema="schema"
            @submit="addComment"
          >
            <vee-field
              as="textarea"
              class="block w-full py-1.5 px-3 text-gray-800 border border-gray-300 transition
              duration-500 focus:outline-none focus:border-black rounded mb-4"
              name="comment"
              :placeholder="$t('song.comment_placeholder')">
            </vee-field>

            <ErrorMessage class="text-red-600 mb-3 block" name="comment" />

            <button
              type="submit"
              class="py-1.5 px-3 rounded text-white bg-gray-500 block"
              :disabled="commentInSubmission">
              {{ $t('global.submit') }}
            </button>
          </vee-form>

          <select
            v-model="sort"
            class="block mt-4 py-1.5 px-3 text-gray-800 border border-gray-300 transition
            duration-500 focus:outline-none focus:border-black rounded">
            <option value="1">
              {{ $t('song.latest') }}
            </option>
            <option value="2">
              {{ $t('song.oldest') }}
            </option>
          </select>
        </div>
      </div>
    </section>

    <transition-group name="list" tag="ul" class="container mx-auto">
      <li
        v-for="comment in sortedComments"
        :key="comment.docId"
        class="p-6 bg-gray-50 border border-gray-200 flex justify-between items-center"
      >
        <div>
          <div class="mb-5">
            <div class="font-bold">
              {{ comment.name }}
            </div>
            <time>
              {{ formatDate(comment.datePosted) }}
            </time>
          </div>
          <p>
            {{ comment.content }}
          </p>
        </div>
        <button class="cursor-pointer p-3" @click="deleteComment(comment)">
          <i class="fas fa-trash text-gray-400 hover:text-gray-600 transition-all duration-300"></i>
        </button>
      </li>
    </transition-group>
  </main>
</template>

<script setup>
import {
  ref, computed, watch, onBeforeMount, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';

const store = useStore();
const route = useRoute();
const router = useRouter();

const paramsId = computed(() => route.params.id);

const song = ref({});

const schema = ref({ comment: 'required|min:3' });

const commentInSubmission = ref(false);
const commentShowAlert = ref(false);
const commentAlertVariant = ref('bg-gray-300');
const commentAlertMessage = ref('Please wait! Your comment is being submitted');
const sort = ref('1');

const userLoggedIn = computed(() => store.getters.userLoggedIn);
const currentSong = computed(() => store.getters.currentSong);
const isPlaying = computed(() => store.getters.isPlaying);
const comments = computed(() => store.getters.comments);

const sortedComments = computed(() => comments.value.slice().sort((a, b) => {
  if (sort.value === '1') {
    return new Date(b.datePosted) - new Date(a.datePosted);
  }
  return new Date(a.datePosted) - new Date(b.datePosted);
}));

async function getComments() {
  await store.dispatch('getComments', paramsId.value);
  song.value.comment_count = comments.value.length ? comments.value.length : 0;
}

async function addComment(values, { resetForm }) {
  commentInSubmission.value = true;
  commentShowAlert.value = true;
  commentAlertVariant.value = 'bg-gray-300';
  commentAlertMessage.value = 'Please wait! Your comment is being submitted';

  const id = paramsId.value;
  song.value.comment_count += 1;
  const count = song.value.comment_count;

  await store.dispatch('addComment', { values, id, count });
  await getComments();

  commentInSubmission.value = false;
  commentAlertVariant.value = 'bg-purple-400';
  commentAlertMessage.value = 'Comment added!';

  resetForm();

  commentShowAlert.value = false;
}

async function deleteComment(comment) {
  const id = paramsId.value;
  song.value.comment_count -= 1;
  const count = song.value.comment_count;
  await store.dispatch('deleteComment', { comment, id, count });
  await getComments();
}

function formatDate(date) {
  return moment(date).format('LLL');
}

async function renderSongs() {
  const id = paramsId.value;
  const docSnapshot = await store.dispatch('getDocSnapshot', { id });

  if (docSnapshot && !docSnapshot.exists) {
    router.push({ name: 'home' });
  }

  sort.value = route.query.sortValue || '1';

  const data = docSnapshot ? docSnapshot.data() : null;
  song.value = {
    docId: paramsId.value,
    ...data,
  };
}

function playSong(songItem) {
  if (!currentSong.value || songItem.docId !== currentSong.value.docId) {
    store.dispatch('newSong', songItem);
  } else {
    store.dispatch('toggleAudio');
  }
}

onBeforeMount(async () => {
  await getComments();

  await store.commit('getCurrentSong');
  await store.commit('getCurrentPlaylist');
});

onMounted(async () => {
  await renderSongs();
});

watch(() => sort.value, (newVal) => {
  if (newVal === route.query.sort) return;
  router.push({
    query: {
      sort: newVal,
    },
  });
});
</script>

<style lang="scss" scoped>
@import "src/assets/main.scss";

.play-button {
  @media (max-width: 600px) {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
  }
}

.comments-list {
  @media (max-width: 600px) {
    margin-top: 0;
  }
}
</style>
