<template>
  <div class="bg-white rounded border border-gray-200 relative flex flex-col">
    <div class="px-6 pt-6 pb-5 font-bold border-b border-gray-200">
      <span class="card-title">
        {{ $t('upload.upload') }}
      </span>
      <i class="fas fa-upload float-right text-gray-400 text-2xl"></i>
    </div>

    <div class="p-6">
      <div
        class="upload-dropbox w-full px-10 py-20 rounded text-center cursor-pointer border border-dashed
        border-gray-400 text-gray-400 transition duration-500 hover:text-white
        hover:bg-purple-400 hover:border-purple-400 hover:border-solid"
        :class="{ 'upload-dropbox-drag': is_dragover }"
        @dragover.prevent.stop="is_dragover = true"
        @dragenter.prevent.stop="is_dragover = true"
        @dragleave.prevent.stop="is_dragover = false"
        @dragend.prevent.stop="is_dragover = false"
        @drop.prevent.stop="uploadSong($event)"
      >
        <h5>
          {{ $t('upload.drop_files') }}
        </h5>
      </div>

      <label
        class="block text-gray-400 mt-5 px-4 py-2 bg-white rounded-md tracking-wide
        border border-blue cursor-pointer hover:bg-gray-600 hover:text-white ease-linear
        transition-all duration-150"
        @drop.stop.prevent
      >
        <input
          type="file"
          multiple
          @change="uploadSong($event)"
          class="hidden"
        >
        {{ $t('upload.choose_file') }}
      </label>

      <hr class="my-6"/>
      <div
        class="mb-4"
        v-for="upload in uploads"
        :key="upload.name"
      >
        <div
          class="font-bold text-sm mb-1 break-words"
          :class="upload.text_class"
        >
          <i :class="upload.icon"></i>
          {{ upload.name }}
        </div>
        <div class="flex h-4 overflow-hidden bg-gray-200 rounded">
          <div class="transition-all progress-bar"
           :class="upload.variant"
           :style="{ width: upload.current_progress + '%' }">
          </div>
        </div>
        <div
          v-if="upload.error_text"
          class="text-red-400 text-sm font-medium mt-1"
        >
          {{ upload.error_text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, onBeforeUnmount, computed } from "vue";

const store = useStore();

const uploads = computed(() => store.getters.uploads)

const is_dragover = ref(false)

const uploadSong = async (e) => {
  await store.dispatch('upload', e);
  is_dragover.value = false;
}

onBeforeUnmount(() => {
  store.dispatch('cancelUploads')
})
</script>

<style lang="scss" scoped>
@import "src/assets/main";

.upload-dropbox {
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
