<template>
  <div class="border border-gray-200 p-3 mb-4 rounded">
    <div v-show="!showForm" class="song">
      <h4 class="inline-block text-xl font-bold song-name">{{ song.modified_name }}</h4>
      <button
        class="ml-1 py-1 px-2 text-sm rounded text-white bg-gray-400 float-right btn delete-song-btn"
        :class="{'bg-red-400': in_submission}"
        @click.prevent="deleteSong"
        :title="$t('manage.delete_song')"
      >
        <i class="fa fa-times"></i>
      </button>
      <button
        class="ml-1 py-1 px-2 text-sm rounded text-white bg-purple-600 float-right btn show-form-btn"
        @click.prevent="showForm = !showForm"
        :title="$t('manage.edit_song')"
      >
        <i class="fa fa-pencil-alt"></i>
      </button>
      <button
        class="ml-1 py-1 px-2 text-sm rounded text-white bg-gray-400 float-right btn visibility-icon"
        :class="{'bg-red-400': in_submission, 'bg-purple-600': isSongVisible}"
        @click.prevent="toggleVisibility"
        :title="$t(`manage.${visibilityIconTooltip}`)"
      >
        <i class="fa fa-eye fa-regular"></i>
      </button>
    </div>

    <div v-show="showForm">
      <div
        v-if="show_alert"
        class="text-white text-center font-bold p-4 mb-4"
        :class="alert_variant"
      >
        {{ $t(alert_message) }}
      </div>

      <CompositionItemForm
        class="song-form"
        :song="song"
        :in_submission="in_submission"
        @edit="edit"
        @closeForm="closeForm"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import CompositionItemForm from "@/components/CompositionItemForm.vue";

const props = defineProps({
  song: {
    type: Object,
    required: true,
  },
})

const store = useStore()

const showForm = ref(false);

const schema = reactive({
  modified_name: 'required',
  genre: 'alpha_spaces',
})

const in_submission = ref(false)
const show_alert = ref(false)
const alert_variant = ref('bg-gray-300')
const alert_message = ref('global.wait')
const isSongVisible = ref(props.song.visibleToAll)

const visibilityIconTooltip = computed(() => props.song.visibleToAll ? 'make_invisible' : 'make_visible')

const showErrorState = () => {
  in_submission.value = false;
  alert_variant.value = 'bg-red';
  alert_message.value = 'global.alert';
}

const edit = async (values) => {
  in_submission.value = true;
  show_alert.value = true;
  alert_variant.value = 'bg-gray-300';
  alert_message.value = 'global.wait';

  const { song } = props
  const { url } = song

  try {
    await store.dispatch('editComposition', { song, values })
  } catch (error) {
    showErrorState()
    return;
  }

  await store.dispatch('updateSong', { url, values });

  in_submission.value = false;
  alert_variant.value = 'bg-purple-400';
  alert_message.value = 'global.success';

  setTimeout(() => {
    show_alert.value = false;
    showForm.value= false;
  }, 2000);
}

const toggleVisibility = async () => {
  isSongVisible.value = !isSongVisible.value;
  const { song } = props;
  const isVisible = isSongVisible.value

  try {
    await store.dispatch('updateCompositionVisibility', { song, isVisible })
  } catch (error) {
    showErrorState()
    return;
  }

  const { url } = song;
  const values = { ...props.song, visibleToAll: isSongVisible.value };
  await store.dispatch('updateSong', { url, values });

  setTimeout(() => {
    show_alert.value = false;
    showForm.value = false;
  }, 2000);
}

const deleteSong = async() => {
  in_submission.value = true;
  const { song } = props

  try {
    await store.dispatch('deleteSong', song)
    await store.dispatch('removeSong', song);
  } catch (e) {
    console.log(e);
  }
}

const closeForm = () => {
  showForm.value = false;
  show_alert.value = false;
}
</script>

<style lang="scss" scoped>
@import "src/assets/mixins.scss";

.song-name {
  @media (max-width: 1200px) {
    max-width: 78%;
    @include white-space;
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    max-width: 70%;
  }
  @media (max-width: 380px) {
    max-width: 60%;
  }
}

.btn {
  @media (max-width: 768px) {
    margin-top: 5px;
    font-size: 0.6rem;
    line-height: 1rem;
  }
}
</style>
